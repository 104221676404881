<template>
  <b-dropdown-item
    link-class="d-flex align-items-center d-lg-none"
    @click="skin = isDark ? 'light' : 'dark'"
  >
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      class="mr-50"
    />
    <span v-if="!isDark">Sötét mód</span>
    <span v-if="isDark">Világos mód</span>
  </b-dropdown-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdownItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
