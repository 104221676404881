<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      Made With
      <feather-icon icon="HeartIcon" />
      by
      <b-link
        class="ml-25"
        href="https://komjit.eu/"
        target="_blank"
      >KomjIT</b-link>
    </span>
    <span class="float-md-right d-block d-md-inline-block mt-25">
      v1.1 Beta
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { appName } = useAppConfig()
    return { appName }
  },
  components: {
    BLink,
    FeatherIcon
  },
}
</script>
