export default [
    {
        title: 'menu.dashboard',
        route: 'home',
        icon: 'HomeOutline',
    },
    {
        title: 'menu.fileManager',
        route: 'file-manager',
        icon: 'GoogleDrive',
        meta: {
            'role': 'fileManager',
            'role-method': 'all'
        },
    },
    {
        title: 'menu.clients',
        route: 'client-list',
        icon: 'Domain',
        children: [
            {
                title: 'menu.list',
                route: 'clients',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-client',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'client',
        },
    },
    {
        title: 'menu.legalPersons',
        route: 'legal-person-list',
        icon: 'ScaleBalance',
        children: [
            {
                title: 'menu.list',
                route: 'legal-persons',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-legal-person',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'legalPerson',
        },
    },
    {
        title: 'menu.users',
        route: 'user-list',
        icon: 'AccountOutline',
        children: [
            {
                title: 'menu.list',
                route: 'users',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-user',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'user',
        },
    },
    {
        title: 'menu.roles',
        route: 'role-list',
        icon: 'LockOutline',
        children: [
            {
                title: 'menu.list',
                route: 'roles',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-role',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'role',
        },
    },
    {
        title: 'menu.emailTemplates',
        route: 'email-templates',
        icon: 'EmailEdit',
        meta: {
            role: 'emailTemplate',
            'role-method': 'list',
        },
    },
    {
        title: 'menu.options',
        route: 'options',
        icon: 'CogOutline',
        meta: {
            role: 'option',
            'role-method': 'list',
        },
    },
]
