import acl from '@/libs/permission'
import menuItems from "@/navigation/vertical/menuItems";

// elérhető menüopontokkal térünk vissza
export default {

  getMenuItems() {
    // Szerepkör szerinti ellenőrzés
    let availableMenuItems = []

    menuItems.forEach(element => {
      // ha nincs role, akkor betesszük és megyünk tovább
      if (element.meta === undefined || element.meta.role === undefined) {
        availableMenuItems.push(element)
        return
      }

      // menüpont fő szerepköre, ha nincs joga akkor skippeljük a menüpontot, nem lesz renderelve
      const role = element.meta.role
      if (!acl.canRole(role)) {
        return
      }

      // van almenü?
      // igen
      if (element.children !== undefined && element.children.length) {
        const childElement = element.children
        let children = []

        childElement.forEach(child => {
          // ha nincs role, akkor betesszük és megyünk tovább
          if (child.meta === undefined || child.meta['role-method'] === undefined) {
            children.push(child)
            return
          }

          // menüpont fő szerepköre, ha nincs joga akkor skippeljük a menüpontot, nem lesz renderelve
          const roleMethod = child.meta['role-method']
          if (!acl.can(role, roleMethod)) {
            return
          }

          // mehet a gyerek a helyére
          children.push(child)
        })

        let el2 = element
        el2.children = children

        // hozzáadjuk
        availableMenuItems.push(el2)

        // continue -> következő menüpont vizsgálata
        return
      }

      // nem
      availableMenuItems.push(element)
    });

    return availableMenuItems
  }
};

