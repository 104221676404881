<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <VBtn
          icon
          variant="text"
          color="default"
          size="small"
      >
        <dark-Toggler class="d-none d-lg-block"/>
      </VBtn>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <language-selector />
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status">{{ userRole }}</span>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
          />
        </template>
        <dark-toggler-mobile/>

        <b-dropdown-divider
            class="d-lg-none"
        />

        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Kijelentkezés</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BIconArrowLeft,
  BNavItem,
  BTooltip,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import DarkTogglerMobile from '@core/layouts/components/app-navbar/components/DarkTogglerMobile.vue'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from 'vuex'
import {VProgressCircular, VBtn} from "vuetify/lib/components"
import LanguageSelector from '@/layouts/components/LanguageSelector.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BIconArrowLeft,
    BNavItem,
    BTooltip,
    VProgressCircular,
    VBtn,
    // Navbar Components
    DarkToggler,
    DarkTogglerMobile,
    LanguageSelector,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters([
      'userName',
      'userRole',
      'isLogged',
    ]),
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$helpers.logout()
        this.$helpers.showSuccessToast('Sikeres kijelentkezés')
      })
    },
  }
}
</script>
